@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import "./form";

.App{
    @for $i from 1 through 6 {
        #{'h' + $i}{
            font-family: 'Roboto Slab', sans-serif;
        }
    }
    img{
        width: auto;
        height: auto;
        object-fit: contain;
    }
    .wp-block-image{
        position: relative;
        overflow: hidden;
        img{
            // width: 100%;
            // height: 100%;
            object-fit: contain;
        }
    }
    .wp-block-media-text{
        .wp-block-media-text__media{
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        &.is-stacked-on-mobile{
            @media (max-width: 465px) {
                display: block !important;
                // background-color: green;
            }
        }
        &.contact-block{
            padding:0;
            max-width: 600px;
            // margin:-60px auto 0;
            transform: translate(0, -60px);
            box-shadow: 0 0 26px rgba(#000, .16);
            .wp-block-media-text__media{
                img{
                    max-height: 240px;
                    object-position: top;
                }
            }
        }
    }
    // background-color: red;

    #stappen{
        .wp-block-column{
            .wp-block-image{
                height: 260px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-fit: cover;
                }
            }
        }
    }


    .alignwide{
        max-width: 1200px;
        width: calc(100% - 40px);
        padding:20px;
        margin: 0 auto;
    }
    #header{
        &.wp-block-media-text{
            min-height: 90vh;
            .wp-block-media-text__media{
                position: relative;
                max-height: 90vh;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    object-fit: cover;
                }
            }
        }
    }
    .wp-block-buttons{
        .wp-block-button{
            .wp-block-button__link{
                &:hover{
                    background-color: #BE651A;
                    color:#fff;
                }
            }
            &:hover{
                .wp-block-button__link{
                    background-color: #BE651A !important;
                    color:#fff;
                }
            }
        }
    }
}

