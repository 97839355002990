form.wpcf7-form{
    
    label{
        display: block;
        width: 100%;
    }
    p{
        width: 100%;
        // @include flex(center, space-between, row nowrap);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row nowrap;
        @media ( max-width: 460px ){
            flex-flow: row wrap;
            // @include flex(center, space-between, row wrap);
        }
        input:not([type="submit"]), textarea{
            width: 100%;
            padding:10px 20px;
            border:0;
            background: rgb(221, 221, 221);
            margin:10px 0 0 0;
        }
        span{
            width: 100%;
            &:not(:last-child){
                margin-right: 20px;
                @media ( max-width: 460px ){
                    margin-right: unset;
                }
            }
        }
        input[type="submit"]{
            background: #ff8900; //#BE651A;
            color:#fff;
            border:0;
            padding:10px 20px;
            margin:0 auto;
        }
        span.ajax-loader{
            position: absolute;
            left:calc(50% - 120px);
        }
    }
}